import React, {Component} from "react"
import styles from "./Navigation.module.css"
import classNames from "classnames"
import {FormID} from "interfaces"
import {observer} from "mobx-react"
import {Item} from "./parts/Item"
import {observable} from "mobx"

interface Props {
    checkedItems: FormID[]
    enabledItems: FormID[]
    selected: FormID
    onChange: (form_id: FormID) => void
    mode: "mobile" | "tablet" | "desktop"
    className?: string
}

const tabs = [
    {
        id: "1",
        title: "Career Information",
        tabletTitle: "Career Info",
        items: [
            {
                label: "Program Selection",
                value: "application_career_information"
            }
        ]
    },
    {
        id: "2",
        title: "General Information",
        tabletTitle: "General Info",
        items: [
            {
                label: "Personal",
                value: "application_personal_information"
            },
            {
                label: "Contact",
                value: "application_contact_details"
            },
            {
                label: "Emergency",
                value: "application_emergency_contact"
            },
            {
                label: "Additional",
                value: "application_additional_information"
            }
        ]
    },
    {
        id: "3",
        title: "Employment",
        tabletTitle: "Employment",
        items: [
            {
                label: "Employment Details",
                value: "application_employment_details"
            }
        ]
    },
    {
        id: "4",
        title: "High School Information",
        tabletTitle: "High School",
        items: [
            {
                label: "High School Details",
                value: "application_high_school_details"
            }
        ]
    },
    {
        id: "5",
        title: "Previous College",
        tabletTitle: "Previous",
        items: [
            {
                label: "Experience",
                value: "application_previous_college"
            }
        ]
    },
    {
        id: "6",
        title: "Forms",
        tabletTitle: "Forms",
        items: [
            {value: "application", label: "Application"},
            {value: "education_disclosure", label: "Education Disclosure"},
            {value: "code_of_computing", label: "Code of Computing"},
            {value: "felony_acknowledgement", label: "Felony Acknowledgement"},
            {value: "identity_authentication", label: "Identity Authentication"},
            {value: "immunization_verification", label: "Immunization Verification"},
            {value: "online_education_disclosure", label: "Online Education Disclosure"},
            {value: "transcript_request", label: "Transcript Request"},
            {value: "transferability_of_credits", label: "Transferability Of Credits"},
            {value: "covid_19", label: "Covid 19"},
            {value: "cpa_eligibility", label: "CPA Eligibility"},
            {value: "technical_standards", label: "Technical Standards"},
            {value: "him_technical_standards", label: "HIM Technical Standards"},
            {value: "him_enrollment_agreement", label: "HIM Enrollment Agreement"},
            {value: "recruitment", label: "Recruitment"}
        ]
    },
    {
        id: "7",
        title: "FA Appointment",
        tabletTitle: "FA Appointment",
        items: [
            {
                label: "Appointment details",
                value: "appointment_details"
            }
        ]
    },
    {
        id: "8",
        title: "Submission",
        tabletTitle: "Submission",
        items: [
            {
                label: "Submission",
                value: "submission"
            }
        ]
    }
]

@observer
export class Navigation extends Component<Props, {}> {
    @observable
    private openedTab: string = null

    public componentDidMount(): void {
        if (this.props.mode === "desktop") {
            this.openedTab = this.getTabIdByFormId(this.props.selected)
        } else {
            this.openedTab = null
        }
    }

    public componentDidUpdate(prevProps: Props) {
        if (this.props.selected === prevProps.selected) {
            return
        }

        if (this.props.mode === "desktop") {
            this.openedTab = this.getTabIdByFormId(this.props.selected)
        } else {
            this.openedTab = null
        }
    }

    private getTabIdByFormId(form_id: FormID): string {
        for (const tab of tabs) {
            const hasForm = tab.items.find((el) => {
                if (el.value === form_id) {
                    return true
                }
                return false
            })
            if (hasForm) {
                return tab.id
            }
        }

        return null
    }

    public render() {
        const {selected, onChange, className} = this.props
        return (
            <div className={classNames(styles.root, className)}>
                <ul className={styles.list}>
                    {tabs.map((el) => {
                        return (
                            <Item
                                key={el.id}
                                id={el.id}
                                title={el.title}
                                tabletTitle={el.tabletTitle}
                                items={el.items as any}
                                onTitleClick={(tab_id) => {
                                    console.log("TAB " + tab_id)
                                    this.openedTab = tab_id
                                }}
                                onClick={(id: FormID) => {
                                    if (this.props.mode !== "desktop") {
                                        // Close submenu for tablet and mobile
                                        this.openedTab = null
                                    }
                                    onChange(id)
                                }}
                                selected={selected}
                                checkedItems={this.props.checkedItems}
                                enabledItems={this.props.enabledItems}
                                isOpen={this.openedTab === el.id}
                            />
                        )
                    })}
                </ul>
            </div>
        )
    }
}
