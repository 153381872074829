import React, {Component} from "react"
import classNames from "classnames"
import styles from "./HighSchoolDetails.module.css"
import {Label} from "components/inputs/Label"
import {Input} from "components/inputs/input"
import {RadioButton} from "components/inputs/RadioButton"
import {LabelRadio} from "components/inputs/LabelRadio"
import {JsonDocument, Model} from "Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {findErrors} from "lib/functions/findErrors"
import {DataStore} from "data/DataStore"
import {Select} from "components/inputs/Select"
import {REFILL_FORMS_IDS, refreshSignaturesOrInitials} from "lib/functions/helper"

interface Props {
    model: Model
}

const mandatory = {
    presence: {allowEmpty: false}
}
const rules = {
    high_school_or_ged: mandatory,
    high_school_testing_site: mandatory,
    date_diploma_certificate: mandatory,
    school_city: mandatory,
    school_state: mandatory,
    school_zip: {
        ...mandatory,
        format: {
            pattern: /^\d{5}(?:[-\s]\d{4})?$/,
            flags: "gm",
            message: "^Invalid zip code format [XXXXX or XXXXX-XXXX]"
        }
    },
    school_country: mandatory,
    education_equivalent: mandatory,
    native_language: mandatory
}

const languajeRules = {
    primary_language: mandatory
}

@observer
export class HighSchoolDetails extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.json_data = props.model.document.json_data

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }
    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        console.log("Validate HighSchoolDetails")
        let newRules = rules
        if (this.json_data.native_language === "no") {
            newRules = {...newRules, ...languajeRules}
        }
        const {isValid, errors} = findErrors(this.json_data, newRules)
        this.errors = errors

        return isValid
    }

    private get dataStore(): DataStore {
        return this.props.model.dataStore
    }

    private onChange(id: string, value) {
        this.json_data[id] = value
        this.removeIdsFromDocument(this.json_data)
        this.forceUpdate()
        this.props.model.dispatcher.dispatch()
    }

    private removeIdsFromDocument(json_data) {
        refreshSignaturesOrInitials(
            json_data,
            [...REFILL_FORMS_IDS.application.forms, ...REFILL_FORMS_IDS.withNoOnchange.forms],
            [...REFILL_FORMS_IDS.application.ids, ...REFILL_FORMS_IDS.withNoOnchange.ids]
        )
    }

    public render() {
        const p = {json_data: this.json_data, onChange: this.onChange, errors: this.errors}
        const r = {onChange: this.onChange, json_data: this.json_data}

        return (
            <div className={styles.root}>
                <h2 className={styles.h2}>HIGH SCHOOL DETAILS</h2>
                <h3 className={classNames(styles.h3, styles.hidden)}>SCHOOL INFORMATION</h3>
                <div className={styles.firstLine}>
                    <LabelRadio
                        text="High School/GED"
                        required
                        className={styles.radio}
                        error={this.errors["high_school_or_ged"]}>
                        <>
                            <RadioButton
                                label="High School"
                                value="High School"
                                group_name="high_school_or_ged"
                                {...r}
                            />
                            <RadioButton
                                label="GED"
                                value="GED"
                                group_name="high_school_or_ged"
                                {...r}
                            />
                        </>
                    </LabelRadio>

                    <Label text="High School / Testing Site" required className={styles.label}>
                        <Input id="high_school_testing_site" {...p} />
                    </Label>
                </div>
                <div className={styles.form}>
                    <Label
                        text="Date Diploma / Date Certificate"
                        required
                        className={styles.labelM0}>
                        <Input id="date_diploma_certificate" type="date" {...p} />
                    </Label>

                    <Label text="Street Address" className={styles.label}>
                        <Input id="school_street_address" {...p} />
                    </Label>

                    <Label text="City" required className={styles.label}>
                        <Input id="school_city" {...p} />
                    </Label>

                    <Label text="State" required className={styles.label}>
                        <Select
                            id="school_state"
                            options={this.dataStore.getAllStates()}
                            onChange={this.onChange}
                            model={this.props.model}
                            errors={this.errors}
                        />
                    </Label>

                    <Label text="ZIP Code" required className={styles.label}>
                        <Input id="school_zip" {...p} />
                    </Label>

                    <Label text="Country" required className={styles.label}>
                        <Input id="school_country" {...p} />
                    </Label>

                    <Label
                        text="Statement on High School / Equivalent education"
                        required
                        className={styles.select}>
                        <Select
                            id="education_equivalent"
                            options={this.dataStore.education_equivalent}
                            onChange={this.onChange}
                            model={this.props.model}
                            errors={this.errors}
                        />
                    </Label>
                </div>
                <div className={styles.firstLine}>
                    <LabelRadio
                        text="Is your native language English?"
                        required
                        className={styles.radio}
                        error={this.errors["native_language"]}>
                        <>
                            <RadioButton
                                label="Yes"
                                value="yes"
                                group_name="native_language"
                                {...r}
                            />
                            <RadioButton
                                label="No"
                                value="no"
                                group_name="native_language"
                                {...r}
                            />
                        </>
                    </LabelRadio>
                    {this.json_data.native_language === "no" && (
                        <LabelRadio
                            text="Did you graduate from a Highschool or Higher education institution in which the primary language of instruction was English?"
                            required
                            className={styles.radioLanguaje}
                            error={this.errors["primary_language"]}>
                            <>
                                <RadioButton
                                    label="Yes"
                                    value="yes"
                                    group_name="primary_language"
                                    {...r}
                                />
                                <RadioButton
                                    label="No"
                                    value="no"
                                    group_name="primary_language"
                                    {...r}
                                />
                            </>
                        </LabelRadio>
                    )}
                </div>
                <p className={styles.text}>
                    <span>*</span> Indicates that a response is required
                </p>
            </div>
        )
    }
}
