import {action, computed, observable, runInAction, toJS} from "mobx"
import {Gate} from "./lib/gate/Gate"
import {Action, History, Location} from "history"
import {User, Visitor} from "./lib/gate/interfaces"
import {Form, FormID} from "./interfaces"
import {route} from "./lib/functions/route"
import {DataStore} from "./data/DataStore"
import {EventDispatcher} from "./lib/gate/EventDispatcher"
import Swal from "sweetalert2"

const forms: Form[] = [
    {
        form_id: "application_career_information",
        title: "APPLICATION / CAREER INFORMATION",
        type: "web",
        enabled: true
    },
    {
        form_id: "application_personal_information",
        title: "application_personal_information",
        type: "web",
        enabled: true
    },
    {
        form_id: "application_contact_details",
        title: "application_contact_details",
        type: "web",
        enabled: true
    },
    {
        form_id: "application_emergency_contact",
        title: "application_emergency_contact",
        type: "web",
        enabled: true
    },

    {
        form_id: "application_additional_information",
        title: "Application / Additional Information",
        type: "web",
        enabled: true
    },
    {
        form_id: "application_employment_details",
        title: "APPLICATION / EMPLOYMENT DETAILS",
        type: "web",
        enabled: true
    },
    {
        form_id: "application_high_school_details",
        title: "APPLICATION / HIGH SCHOOL DETAILS",
        type: "web",
        enabled: true
    },

    {
        form_id: "application_previous_college",
        title: "APPLICATION / PREVIOUS COLLEGE",
        type: "web",
        enabled: true
    },
    {form_id: "application", title: "Application", type: "pdf", enabled: true},
    {form_id: "education_disclosure", title: "Education Disclosure", type: "pdf", enabled: true},
    {form_id: "code_of_computing", title: "Code of Computing", type: "pdf", enabled: true},
    {
        form_id: "felony_acknowledgement",
        title: "Felony Acknowledgement",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "identity_authentication",
        title: "Identity Authentication",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "immunization_verification",
        title: "Immunization Verification",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "online_education_disclosure",
        title: "Online Education Disclosure",
        type: "pdf",
        enabled: true
    },
    {form_id: "transcript_request", title: "Transcript Request", type: "pdf", enabled: true},
    {
        form_id: "transferability_of_credits",
        title: "Transferability Of Credits",
        type: "pdf",
        enabled: true
    },
    // {form_id: "signature_certificate", title: "Signature Certificate", type: "pdf"},
    {form_id: "covid_19", title: "Covid 19", type: "pdf", enabled: true},
    {form_id: "cpa_eligibility", title: "CPA Eligibility", type: "pdf", enabled: true},
    {form_id: "technical_standards", title: "Technical Standards", type: "pdf", enabled: true},
    {
        form_id: "him_technical_standards",
        title: "HIM Technical Standards",
        type: "pdf",
        enabled: true
    },
    {
        form_id: "him_enrollment_agreement",
        title: "HIM Enrollment Agreement",
        type: "pdf",
        enabled: true
    },
    {form_id: "recruitment", title: "Recruitment", type: "pdf", enabled: true},
    {
        form_id: "appointment_details",
        title: "appointment_details",
        type: "web",
        enabled: true
    },
    {
        form_id: "submission",
        title: "submission",
        type: "web",
        enabled: true
    }
]

export class Model {
    public dispatcher = new EventDispatcher()
    public dispatcher2 = new EventDispatcher()
    private validateFunctions: Function[] = []

    @observable
    public document: UserDocument = {json_data: {}}

    @observable
    public signaturePopup: {show: boolean; signature_key; mode: "signature" | "initials"} = {
        show: false,
        signature_key: "",
        mode: "signature"
    }

    @observable
    private form_id: FormID = "application_career_information"

    @observable
    public iframeLoaded = false

    @observable
    public location: Location

    @observable
    public path: string

    @observable
    public visitor: Visitor

    @observable
    public user: User | undefined

    @observable
    public appSettings: any

    @observable
    public popup = {visible: false, message: ""}

    public childBridge: any

    @observable
    public is_form_submitted = false

    private parentBridge: any

    constructor(public gate: Gate, private history: History, public dataStore: DataStore) {
        this.history = history
        history.listen(this.onHistoryChange.bind(this))
        this.onHistoryChange(history.location, "PUSH")
        // @ts-ignore
        this.parentBridge = window.parent.bridge
    }

    public updatePdfView() {
        let json_data
        if (this.form.type === "pdf") {
            if (!this.childBridge) {
                throw new Error("No childBridge! Please check bridge set in admissions-pdf")
            }

            json_data = this.childBridge.json_data
            this.document.json_data = json_data
            this.dispatcher.dispatch()
        }
    }
    public canSubmit(): boolean {
        const allEnabledForms = forms
            .filter((f) => {
                return f.enabled
            })
            .map((f) => {
                return f.form_id
            })
            .filter((form_id) => {
                return form_id !== "submission"
            })

        const passedForms = Object.keys(this.document.json_data.forms).filter(
            (form_id) => allEnabledForms.indexOf(form_id as FormID) !== -1
        )

        if (allEnabledForms.length === passedForms.length) {
            return true
        }

        return false
    }

    public enableForm(form_id: FormID) {
        for (let form of forms) {
            if (form.form_id === form_id) {
                form.enabled = true
                break
            }
        }

        this.dispatcher.dispatch()
    }

    public disableForm(form_id: FormID) {
        for (let form of forms) {
            if (form.form_id === form_id) {
                form.enabled = false
                break
            }
        }
        this.dispatcher.dispatch()
    }

    @action
    public setFormId(id: FormID) {
        console.log("SET FORM ID=", id)
        // this.form_id = id
        this.goTo(`/form/${id}?token=${this.gate.getToken()}&user_id=${this.user?.id}`)
        this.iframeLoaded = false
    }

    @computed
    public get form(): Form {
        return forms.find((el) => {
            return el.form_id === this.form_id
        })
    }

    public goTo(url: string) {
        console.log(`GO TO URL:${url}`)
        this.history.push(url)
    }

    public getForms(): Form[] {
        return forms
    }

    public get enabledItems(): FormID[] {
        let arr = forms.filter((el) => {
            return el.enabled
        })

        return arr.map((el: Form) => {
            return el.form_id
        })
    }

    public getPreviousFormId(): FormID {
        let index: number
        for (let i = 0; i < forms.length; i++) {
            if (forms[i].form_id === this.form_id) {
                index = i
                break
            }
        }
        if (index === 0) {
            return null
        }

        for (let i = index - 1; i >= 0; i--) {
            if (forms[i].enabled) {
                return forms[i].form_id
            }
        }

        return null
    }

    public getNextFormId(): FormID {
        let index: number
        for (let i = 0; i < forms.length; i++) {
            if (forms[i] && forms[i].form_id === this.form_id) {
                index = i
                break
            }
        }

        if (index + 1 === forms.length) {
            return null
        }

        for (let i = index + 1; i < forms.length; i++) {
            if (forms[i].enabled) {
                return forms[i].form_id
            }
        }

        return null
    }

    private onHistoryChange(location: Location, action: Action) {
        this.path = location.pathname
        this.location = location
        console.log("ON HISTORY Change=", this.path)
        if (route("/form/:id(/)", this.path)) {
            const {id} = route("/form/:id(/)*", this.path)
            this.form_id = id
        }
    }

    @computed
    public get planStaffUserId(): number {
        if (this.appSettings && this.appSettings.plan_staff_user_id) {
            return parseInt(this.appSettings.plan_staff_user_id)
        }

        return 0
    }

    @computed
    public get agreementStaffUserId(): number {
        // return 60347
        if (this.appSettings && this.appSettings.agreement_staff_user_id) {
            return parseInt(this.appSettings.agreement_staff_user_id)
        }

        return 0
    }

    @action
    public alert(message: string) {
        this.popup.visible = true
        this.popup.message = message
    }

    public get isStaff(): boolean {
        if (!this.visitor) {
            return false
        }
        const role = this.visitor.role
        if (role === "staff" || role === "admin") {
            return true
        }

        return false
    }

    public async loadAppSettings() {
        let r = await this.gate.request("/app-settings/get")
        this.appSettings = r.data.json_data
    }

    public async loadMe(): Promise<void> {
        let r = await this.gate.request("/users/get-me")
        if (!r.success) {
            this.alert("API Error!" + JSON.stringify(r.errors))
        }
        this.visitor = r.data
    }

    public async loadUser(user_id: number): Promise<void> {
        let r = await this.gate.request(`/users/${user_id}/get`)
        this.user = r.data
    }

    public getHistory(): History {
        return this.history
    }

    public async submit(): Promise<void> {
        const responseSubmit = await this.gate.request(`/users/${this.user.id}/submit`)
        try {
            if (responseSubmit.success) {
                await Swal.fire({
                    icon: "success",
                    title: "Application submitted",
                    text: "Thank you for submit your application. An advisor will contact you soon."
                })
            } else {
            }
        } catch (error) {}

        runInAction(() => {
            this.is_form_submitted = true
        })
    }

    // public async submit(): Promise<boolean> {
    //     let {success} = await this.gate.request(`/users/${this.user.id}/submit`)
    //     runInAction(() => {
    //         this.is_form_submitted = true
    //     })

    //     return success
    // }

    public async save(): Promise<boolean> {
        let json_data

        if (this.form.type === "pdf") {
            if (!this.childBridge) {
                throw new Error("No childBridge! Please check bridge set in admissions-pdf")
            }
            if (!(await this.childBridge.validate())) {
                return false
            }
            json_data = this.childBridge.json_data
            this.document.json_data = json_data
            this.dispatcher.dispatch()
        }

        if (this.form.type === "web") {
            if (!this.validate()) {
                return false
            }

            json_data = this.document.json_data
        }

        if (!json_data) {
            return false
        }

        json_data.forms = json_data.forms || {}
        json_data.forms[this.form.form_id] = this.form

        const id = this.user.doc.id
        if (json_data.email) {
            json_data.email = json_data.email.trim()
        }
        let r = await this.gate.request(`/docs/${id}/edit`, {
            data: {json_data}
        })

        return r.success
    }

    public signOut() {
        this.parentBridge.signOut()
    }

    public async getSignature(type: "signature" | "initials"): Promise<object | null> {
        if (!this.currentSignature) {
            let r = await this.gate.request("/signatures/get-my", {data: {type: type}})
            if (r.success) {
                this.currentSignature = r.data
            } else {
                this.currentSignature = null
            }
        }

        return this.currentSignature
    }

    public addValidateFunction(f: Function) {
        for (let item of this.validateFunctions) {
            if (item === f) {
                return
            }
        }

        this.validateFunctions.push(f)
    }

    public removeValidateFunction(f: Function) {
        console.log("removeValidateFunction")
        // @ts-ignore
        console.log(f.functionId)

        console.log(
            this.validateFunctions.map((e) => {
                // @ts-ignore
                return e.functionId
            })
        )
        for (let i = 0; i < this.validateFunctions.length; i++) {
            // @ts-ignore
            if (this.validateFunctions[i].functionId === f.functionId) {
                this.validateFunctions.splice(i, 1)
                console.log(
                    this.validateFunctions.map((e) => {
                        // @ts-ignore
                        return e.functionId
                    })
                )
                return
            }
        }
    }

    public validate(): boolean {
        let results = []
        for (let f of this.validateFunctions) {
            const r = f()
            results.push(r)
        }

        if (results.includes(false)) {
            return false
        }
        return true
    }

    public async loadVisitor() {
        let r = await this.gate.request("/users/get-me")
        this.visitor = r.data
    }

    public async loadAll(token: string, user_id: number) {
        if (!token || !user_id) {
            return
        }
        this.gate.setToken(token)
        await Promise.all([this.loadUser(user_id), this.loadVisitor()])
        this.document = this.user.doc
        console.log("MODEL>LOAD ALL")
        console.log(toJS(this.document))
        this.form_id =
            (this.document.json_data.progress as FormID) || "application_career_information"

        if (!this.document.json_data.first_name) {
            this.document.json_data.first_name = this.user.first_name
        }
        if (!this.document.json_data.last_name) {
            this.document.json_data.last_name = this.user.last_name
        }

        if (!this.document.json_data.home_phone) {
            this.document.json_data.home_phone = this.user.json_data.phone_number
        }

        this.dispatcher.dispatch()
        this.dispatcher2.dispatch()
    }

    private currentSignature
}

export interface SignatureVO {
    id
    user_id
    json_data: {
        img: string
    }
    ip // "172.17.0.1"
    created_at // "2019-10-17T11:22:43.823Z"
    updated_at // "2019-10-17T11:22:43.823Z"
    deleted_at
}

interface UserDocument {
    id?: number
    // pack_id
    // visitor_id
    // user_id
    // user: User
    // type
    json_data: JsonDocument
    // created_at
    // updated_at
    // deleted_at
}

export interface JsonDocument {
    [key: string]: string
}
