import {CampusVO, ProgramVO, StateVO} from "./interfaces"

export class DataStore {
    constructor(private json: any) {}

    public getAllCampuses(): CampusVO[] {
        return this.json.campuses
    }

    public getAllStates(): SelectOption[] {
        const states: StateVO[] = this.json.states
        return states.map((s) => {
            return {value: s.name, label: s.name}
        })
    }

    public campusesHasOnlineProgram(campuses: string[], programCode) {
        for (let item of campuses) {
            let campusObject = this.getCampusObjectByCode(item)

            if (!campusObject) {
                continue
            }

            if (campusObject.onlinePrograms.indexOf(programCode) === -1) {
                return false
            }
        }

        return true
    }

    public campusHasGroundProgram(campusCode: string, programCode: string): boolean {
        let campusObject = this.getCampusObjectByCode(campusCode)

        if (!campusObject) {
            return false
        }

        if (campusObject.groundPrograms.indexOf(programCode) === -1) {
            return false
        }
        return true
    }

    public getAdvisorsArray(campusName) {
        let json = this.json
        if (json.campuses) {
            for (let i = 0; i < json.campuses.length; i++) {
                if (json.campuses[i].name === campusName) {
                    return json.campuses[i].advisors
                }
            }
        }

        console.log("Warning! No advisers found for campus=" + campusName)
        return null
    }

    public getCampusObject(campusName): CampusVO {
        let json = this.json
        if (json.campuses) {
            for (let i = 0; i < json.campuses.length; i++) {
                if (json.campuses[i].name === campusName) {
                    return json.campuses[i]
                }
            }
        }

        console.log("Warning! No campus found for campusName=" + campusName)
        return null
    }

    public getCampusObjectByCode(campusCode): CampusVO {
        let json = this.json
        if (json.campuses) {
            for (let i = 0; i < json.campuses.length; i++) {
                if (json.campuses[i].code === campusCode) {
                    return json.campuses[i]
                }
            }
        }

        console.log("Warning! No campus found for campusCode=" + campusCode)
        return null
    }

    public getProgramObjectByCode(code): ProgramVO {
        let json = this.json
        if (json.programs) {
            for (let i = 0; i < json.programs.length; i++) {
                if (json.programs[i].code === code) {
                    return json.programs[i]
                }
            }
        }

        console.log("Warning! No program found for code=" + code)
        return null
    }

    public getProgramObjectByName(name: string): ProgramVO {
        let json = this.json
        if (json.programs) {
            for (let i = 0; i < json.programs.length; i++) {
                if (json.programs[i].name === name) {
                    return json.programs[i]
                }
            }
        }

        console.log("Warning! No program found for name=" + name)
        return null
    }

    public getCampusPrograms(campusName): string[] {
        let campus: CampusVO = this.getCampusObject(campusName)
        if (!campus) {
            return null
        }
        let online = campus.onlinePrograms
        let ground = campus.groundPrograms

        let all = online.concat(ground)

        let unique = all.filter(this.onlyUnique)

        let programs: string[] = []
        for (let code of unique) {
            let programObject = this.getProgramObjectByCode(code)
            programs.push(programObject.name)
        }

        programs.sort(function (a, b) {
            if (a < b) return -1
            if (a > b) return 1
            return 0
        })

        return programs
    }

    public getAllPrograms(): ProgramVO[] {
        let json = this.json
        let programs: ProgramVO[] = []

        if (json.programs) {
            programs = json.programs
            programs.sort(function (a, b) {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
                return 0
            })
        }

        return programs
    }

    public getCampusesForProgram(programName: string): CampusVO[] {
        let json = this.json
        let campuses = []
        if (json.programs) {
            let program = json.programs.filter((p) => p.name === programName)[0]
            if (program) {
                if (json.campuses) {
                    for (let i = 0; i < json.campuses.length; i++) {
                        if (
                            json.campuses[i].onlinePrograms.indexOf(program.code) > -1 ||
                            json.campuses[i].groundPrograms.indexOf(program.code) > -1
                        ) {
                            campuses.push(json.campuses[i])
                        }
                    }
                }
            }
        }

        campuses.sort(function (a, b) {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
        })

        return campuses
    }

    private onlyUnique(value, index, self) {
        return self.indexOf(value) === index
    }

    public getStartDates(): SelectOption[] {
        return [
            {value: "Undecided", label: "Undecided"},
            {value: "November 20, 2022", label: "November 20, 2022"},
            {value: "December 25, 2022", label: "December 25, 2022"}
        ]
    }

    // Start Dates Only for CDL program
    public getStartDatesForCDL(): SelectOption[] {
        return [
            {value: "Undecided", label: "Undecided"},
            {value: "October 31, 2022", label: "October 31, 2022"},
            {value: "November 21, 2022", label: "November 21, 2022"},
            {value: "December 12, 2022", label: "December 12, 2022"},
            {value: "January 2, 2023", label: "January 2, 2023"}
        ]
    }

    public getClassTimes(): SelectOption[] {
        return [
            {
                value: "Global Learn Weekend",
                label: "Global Learn Weekend"
            },
            {
                value: "GL - Days",
                label: "GL - Days (students who take day classes or Global Learning During the Day)"
            },
            {
                value: "GL - Nights",
                label:
                    "GL - Nights (students who take night classes or Global Learning during the night session)"
            }
        ]
    }

    public get marital_status(): SelectOption[] {
        return [
            {value: "Married", label: "Married"},
            {value: "Single", label: "Single"},
            {value: "Divorced", label: "Divorced"},
            {value: "Widow", label: "Widow"}
        ]
    }

    public get gender(): SelectOption[] {
        return [
            {value: "Male", label: "Male"},
            {value: "Female", label: "Female"}
        ]
    }

    public get education_equivalent(): SelectOption[] {
        const arr = [
            "I passed all applicable Standards of Learning exams and received a High School Diploma",
            "I am currently attending High School and expect to pass all applicable Standards of Learning exams and receive a Diploma",
            "I completed High School and received a Certificate of Attendance and earned a GED",
            "I completed High School and received a Certificate of Attendance and passed a state-authorized examination that the state recognized as the equivalent of a high School Diploma",
            "I completed High School in a home-school setting",
            "I did not complete High School, but I earned a GED",
            "I did not complete High School, but passed a state-authorized examination that the state recognized as the equivalent of a high School Diploma"
        ]

        return arr.map((item) => {
            return {value: item, label: item}
        })
    }

    public get t_shirt_size(): SelectOption[] {
        const arr = ["S", "M", "L", "XL", "2XL", "3XL"]

        return arr.map((item) => {
            return {value: item, label: item}
        })
    }

    public get getEthnicity(): SelectOption[] {
        return [
            {value: "American Indian or Alaska Native", label: "American Indian or Alaska Native"},
            {
                value: "Hawaii Native or other Pacific Islander",
                label: "Hawaii Native or other Pacific Islander"
            },
            {value: " Black/African American", label: " Black/African American"},
            {value: "Indian", label: "Indian"},
            {value: "Asian", label: "Asian"},
            {value: "White", label: "White"}
        ]
    }
}

export interface SelectOption {
    label: string
    value: string
}
