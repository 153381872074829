export const json = {
    campuses: [
        {
            name: "Undecided",
            code: "undecided",
            advisors: [
                {
                    name: "Unassigned Advisor",
                    email: "admissionsklassapp@an.edu"
                },
                {
                    name: "Chelsea Varney",
                    email: "cvarney@an.edu"
                },
                {
                    name: "Cody Chaney",
                    email: "cchaney@an.edu"
                },
                {
                    name: "Melanie Rutledge",
                    email: "mrutledge@an.edu"
                },
                {
                    name: "Trent Ramey",
                    email: "tramey@an.edu"
                },
                {
                    name: "Chelsea Dorris",
                    email: "cdorris@an.edu"
                },
                {
                    name: "Hilary Carman",
                    email: "hcarman@an.edu"
                },
                {
                    name: "Yvette Arceneaux",
                    email: "yarceneaux@an.edu"
                },
                {
                    name: "Elizabeth Rinehart",
                    email: "erinehart@an.edu"
                },
                {
                    name: "Hannah Jackson",
                    email: "hjackson@an.edu"
                },
                {
                    name: "Kary Alicea",
                    email: "kalicea@an.edu"
                },
                {
                    name: "Jenny Sayer",
                    email: "jsayer@an.edu"
                },
                {
                    name: "LynnSha Howard",
                    email: "lhoward@an.edu"
                },
                {
                    name: "Josh Tucker",
                    email: "jtucker1@an.edu"
                },
                {
                    name: "Megan Warren",
                    email: "mwarren@an.edu"
                },
                {
                    name: "Jennifer Hall",
                    email: "jhall1@an.edu"
                },
                {
                    name: "Paula Vaughan-Burroughs",
                    email: "pvaughanburroughs@an.edu"
                },
                {
                    name: "Karen Williams",
                    email: "kwilliams3@an.edu"
                },
                {
                    name: "Dree Lynn",
                    email: "dlynn@an.edu"
                },
                {
                    name: "Linda Ebel",
                    email: "lebel@an.edu"
                },
                {
                    name: "Racquel Rogers",
                    email: "rrogers@an.edu"
                },
                {
                    name: "Timothy Lawson",
                    email: "timlawson@an.edu"
                },
                {
                    name: "Charles Markum",
                    email: "cmarkum@an.edu"
                }
            ],
            onlinePrograms: [],
            groundPrograms: ["undecided"]
        },
        {
            name: "ANUVA – DISTSalem",
            code: "RKE",
            advisors: [
                {
                    name: "Unassigned Advisor",
                    email: "admissionsklassapp@an.edu"
                },
                {
                    name: "Chelsea Varney",
                    email: "cvarney@an.edu"
                },
                {
                    name: "Cody Chaney",
                    email: "cchaney@an.edu"
                },
                {
                    name: "Melanie Rutledge",
                    email: "mrutledge@an.edu"
                },
                {
                    name: "Anne Snyder",
                    email: "ansnyder@an.edu"
                },
                {
                    name: "Tonya Overbey",
                    email: "toverbey@an.edu"
                },
                {
                    name: "Katie McIlvain",
                    email: "kwilson@an.edu"
                },
                {
                    name: "Mary Oxenrider",
                    email: "moxenrider@an.edu"
                },
                {
                    name: "Jessica Williams",
                    email: "jawilliams@an.edu"
                },
                {
                    name: "Trent Ramey",
                    email: "tramey@an.edu"
                },
                {
                    name: "Chelsea Dorris",
                    email: "cdorris@an.edu"
                },
                {
                    name: "Hilary Carman",
                    email: "hcarman@an.edu"
                },
                {
                    name: "Yvette Arceneaux",
                    email: "yarceneaux@an.edu"
                },
                {
                    name: "Elizabeth Rinehart",
                    email: "erinehart@an.edu"
                },
                {
                    name: "Hannah Jackson",
                    email: "hjackson@an.edu"
                },
                {
                    name: "Kary Alicea",
                    email: "kalicea@an.edu"
                },
                {
                    name: "Jenny Sayer",
                    email: "jsayer@an.edu"
                },
                {
                    name: "LynnSha Howard",
                    email: "lhoward@an.edu"
                },
                {
                    name: "Josh Tucker",
                    email: "jtucker1@an.edu"
                },
                {
                    name: "Megan Warren",
                    email: "mwarren@an.edu"
                },
                {
                    name: "Jennifer Hall",
                    email: "jhall1@an.edu"
                },
                {
                    name: "Paula Vaughan-Burroughs",
                    email: "pvaughanburroughs@an.edu"
                },
                {
                    name: "Karen Williams",
                    email: "kwilliams3@an.edu"
                },
                {
                    name: "Dree Lynn",
                    email: "dlynn@an.edu"
                },
                {
                    name: "Linda Ebel",
                    email: "lebel@an.edu"
                },
                {
                    name: "Racquel Rogers",
                    email: "rrogers@an.edu"
                },
                {
                    name: "Timothy Lawson",
                    email: "timlawson@an.edu"
                },
                {
                    name: "Charles Markum",
                    email: "cmarkum@an.edu"
                }
            ],
            onlinePrograms: [],
            groundPrograms: []
        },
        {
            name: "ANUKY – Louisville (E-Learning)",
            code: "LOU",
            advisors: [
                {
                    name: "Unassigned Advisor",
                    email: "admissionsklassapp@an.edu"
                },
                {
                    name: "Chelsea Varney",
                    email: "cvarney@an.edu"
                },
                {
                    name: "Cody Chaney",
                    email: "cchaney@an.edu"
                },
                {
                    name: "Melanie Rutledge",
                    email: "mrutledge@an.edu"
                },
                {
                    name: "Adrian McQuarter",
                    email: "amcquarter@an.edu"
                },
                {
                    name: "Chequita Parker",
                    email: "cparker1@an.edu"
                },
                {
                    name: "Renee Miller",
                    email: "rmiller@an.edu"
                },
                {
                    name: "Nolan Sanders",
                    email: "nsanders@an.edu"
                },
                {
                    name: "Anne Snyder",
                    email: "ansnyder@an.edu"
                },
                {
                    name: "Tonya Overbey",
                    email: "toverbey@an.edu"
                },
                {
                    name: "Katie McIlvain",
                    email: "kwilson@an.edu"
                },
                {
                    name: "Mary Oxenrider",
                    email: "moxenrider@an.edu"
                },
                {
                    name: "Jessica Williams",
                    email: "jawilliams@an.edu"
                },
                {
                    name: "Becky Reed",
                    email: "breed@an.edu"
                },
                {
                    name: "Trent Ramey",
                    email: "tramey@an.edu"
                },
                {
                    name: "Alana Daniels",
                    email: "adaniels@an.edu"
                },
                {
                    name: "Cleopatra Roberson",
                    email: "croberson1@an.edu"
                },
                {
                    name: "Juliana Cecera",
                    email: "jcecera@an.edu"
                },
                {
                    name: "Toya Bailey",
                    email: "tbailey@an.edu"
                },
                {
                    name: "Michael Van Heesch",
                    email: "mvanheesch@an.edu"
                },
                {
                    name: "Joshua Raines",
                    email: "jraines@an.edu"
                },
                {
                    name: "Thea Allen",
                    email: "tallen1@an.edu"
                },
                {
                    name: "Chelsea Dorris",
                    email: "cdorris@an.edu"
                },
                {
                    name: "Hilary Carman",
                    email: "hcarman@an.edu"
                },
                {
                    name: "Yvette Arceneaux",
                    email: "yarceneaux@an.edu"
                },
                {
                    name: "Elizabeth Rinehart",
                    email: "erinehart@an.edu"
                },
                {
                    name: "Hannah Jackson",
                    email: "hjackson@an.edu"
                },
                {
                    name: "Kary Alicea",
                    email: "kalicea@an.edu"
                },
                {
                    name: "Jenny Sayer",
                    email: "jsayer@an.edu"
                },
                {
                    name: "LynnSha Howard",
                    email: "lhoward@an.edu"
                },
                {
                    name: "Josh Tucker",
                    email: "jtucker1@an.edu"
                },
                {
                    name: "Megan Warren",
                    email: "mwarren@an.edu"
                },
                {
                    name: "Jennifer Hall",
                    email: "jhall1@an.edu"
                },
                {
                    name: "Paula Vaughan-Burroughs",
                    email: "pvaughanburroughs@an.edu"
                },
                {
                    name: "Karen Williams",
                    email: "kwilliams3@an.edu"
                },
                {
                    name: "Dree Lynn",
                    email: "dlynn@an.edu"
                },
                {
                    name: "Linda Ebel",
                    email: "lebel@an.edu"
                },
                {
                    name: "Racquel Rogers",
                    email: "rrogers@an.edu"
                },
                {
                    name: "Timothy Lawson",
                    email: "timlawson@an.edu"
                },
                {
                    name: "Charles Markum",
                    email: "cmarkum@an.edu"
                },
                {
                    name: "Abigail Whelan",
                    email: "awhelan@an.edu"
                },
                {
                    name: "Grechen Murdaugh",
                    email: "gmurdaugh@an.edu"
                },

                {
                    name: "Terry Bartley",
                    email: "tbartley@an.edu"
                },
                {
                    name: "Stephen Hammonds",
                    email: "shammonds@an.edu"
                }
            ],
            onlinePrograms: [],
            groundPrograms: [
                // "HIM17",
                "MAA",
                "undecided",
                "MBA",
                "MSIT",
                "MSC",
                "MSSD",
                "MSCSE",
                "MHSM18",
                "BSLS"
            ]
        },
        {
            name: "ANUKY – Pikeville",
            code: "PVL",
            advisors: [
                {
                    name: "Unassigned Advisor",
                    email: "admissionsklassapp@an.edu"
                },
                {
                    name: "Chelsea Varney",
                    email: "cvarney@an.edu"
                },
                {
                    name: "Cody Chaney",
                    email: "cchaney@an.edu"
                },
                {
                    name: "Melanie Rutledge",
                    email: "mrutledge@an.edu"
                },
                {
                    name: "Anne Snyder",
                    email: "ansnyder@an.edu"
                },
                {
                    name: "Tonya Overbey",
                    email: "toverbey@an.edu"
                },
                {
                    name: "Katie McIlvain",
                    email: "kwilson@an.edu"
                },
                {
                    name: "Mary Oxenrider",
                    email: "moxenrider@an.edu"
                },
                {
                    name: "Jessica Williams",
                    email: "jawilliams@an.edu"
                },
                {
                    name: "Patricia Bailey",
                    email: "pbailey@an.edu"
                },
                {
                    name: "Cheria Goodson",
                    email: "cgoodson@an.edu"
                },
                {
                    name: "Trent Ramey",
                    email: "tramey@an.edu"
                },
                {
                    name: "Chelsea Dorris",
                    email: "cdorris@an.edu"
                },
                {
                    name: "Hilary Carman",
                    email: "hcarman@an.edu"
                },
                {
                    name: "Yvette Arceneaux",
                    email: "yarceneaux@an.edu"
                },
                {
                    name: "Elizabeth Rinehart",
                    email: "erinehart@an.edu"
                },
                {
                    name: "Hannah Jackson",
                    email: "hjackson@an.edu"
                },
                {
                    name: "Kary Alicea",
                    email: "kalicea@an.edu"
                },
                {
                    name: "Jenny Sayer",
                    email: "jsayer@an.edu"
                },
                {
                    name: "LynnSha Howard",
                    email: "lhoward@an.edu"
                },
                {
                    name: "Josh Tucker",
                    email: "jtucker1@an.edu"
                },
                {
                    name: "Megan Warren",
                    email: "mwarren@an.edu"
                },
                {
                    name: "Jennifer Hall",
                    email: "jhall1@an.edu"
                },
                {
                    name: "Paula Vaughan-Burroughs",
                    email: "pvaughanburroughs@an.edu"
                },
                {
                    name: "Karen Williams",
                    email: "kwilliams3@an.edu"
                },
                {
                    name: "Dree Lynn",
                    email: "dlynn@an.edu"
                },
                {
                    name: "Linda Ebel",
                    email: "lebel@an.edu"
                },
                {
                    name: "Racquel Rogers",
                    email: "rrogers@an.edu"
                },
                {
                    name: "Timothy Lawson",
                    email: "timlawson@an.edu"
                },
                {
                    name: "Charles Markum",
                    email: "cmarkum@an.edu"
                },
                {
                    name: "Terry Bartley",
                    email: "tbartley@an.edu"
                },
                {
                    name: "Stephen Hammonds",
                    email: "shammonds@an.edu"
                }
            ],
            onlinePrograms: [],
            groundPrograms: ["ADN", "undecided", "MHSM"]
        },
        {
            name: "ANU - Distance Education Division",
            code: "???",
            advisors: [
                {
                    name: "Unassigned Advisor",
                    email: "admissionsklassapp@an.edu"
                },
                {
                    name: "Chelsea Varney",
                    email: "cvarney@an.edu"
                },
                {
                    name: "Cody Chaney",
                    email: "cchaney@an.edu"
                },
                {
                    name: "Melanie Rutledge",
                    email: "mrutledge@an.edu"
                },
                {
                    name: "Adrian McQuarter",
                    email: "amcquarter@an.edu"
                },
                {
                    name: "Chequita Parker",
                    email: "cparker1@an.edu"
                },
                {
                    name: "Renee Miller",
                    email: "rmiller@an.edu"
                },
                {
                    name: "Nolan Sanders",
                    email: "nsanders@an.edu"
                },
                {
                    name: "Anne Snyder",
                    email: "ansnyder@an.edu"
                },
                {
                    name: "Tonya Overbey",
                    email: "toverbey@an.edu"
                },
                {
                    name: "Katie McIlvain",
                    email: "kwilson@an.edu"
                },
                {
                    name: "Mary Oxenrider",
                    email: "moxenrider@an.edu"
                },
                {
                    name: "Jessica Williams",
                    email: "jawilliams@an.edu"
                },
                {
                    name: "Jamie Stewart",
                    email: "jcstewart@an.edu"
                },
                {
                    name: "Rebecca Harris",
                    email: "rharris@an.edu"
                },
                {
                    name: "Tonya Elmore",
                    email: "telmore@an.edu"
                },
                {
                    name: "Raya Banks",
                    email: "rdbanks@an.edu"
                },
                {
                    name: "Becki Sessions",
                    email: "bsessions@an.edu"
                },
                {
                    name: "Patricia Bailey",
                    email: "pbailey@an.edu"
                },
                {
                    name: "Isaiah Isaac",
                    email: "iisaac@an.edu"
                },
                {
                    name: "Becky Reed",
                    email: "breed@an.edu"
                },
                {
                    name: "Cheria Goodson",
                    email: "cgoodson@an.edu"
                },
                {
                    name: "Trent Ramey",
                    email: "tramey@an.edu"
                },
                {
                    name: "Alana Daniels",
                    email: "adaniels@an.edu"
                },
                {
                    name: "Cleopatra Roberson",
                    email: "croberson1@an.edu"
                },
                {
                    name: "Juliana Cecera",
                    email: "jcecera@an.edu"
                },
                {
                    name: "Toya Bailey",
                    email: "tbailey@an.edu"
                },
                {
                    name: "Michael Van Heesch",
                    email: "mvanheesch@an.edu"
                },
                {
                    name: "Joshua Raines",
                    email: "jraines@an.edu"
                },
                {
                    name: "Thea Allen",
                    email: "tallen1@an.edu"
                },
                {
                    name: "Chelsea Dorris",
                    email: "cdorris@an.edu"
                },
                {
                    name: "Hilary Carman",
                    email: "hcarman@an.edu"
                },
                {
                    name: "Yvette Arceneaux",
                    email: "yarceneaux@an.edu"
                },
                {
                    name: "Elizabeth Rinehart",
                    email: "erinehart@an.edu"
                },
                {
                    name: "Hannah Jackson",
                    email: "hjackson@an.edu"
                },
                {
                    name: "Kary Alicea",
                    email: "kalicea@an.edu"
                },
                {
                    name: "Jenny Sayer",
                    email: "jsayer@an.edu"
                },
                {
                    name: "LynnSha Howard",
                    email: "lhoward@an.edu"
                },
                {
                    name: "Josh Tucker",
                    email: "jtucker1@an.edu"
                },
                {
                    name: "Megan Warren",
                    email: "mwarren@an.edu"
                },
                {
                    name: "Jennifer Hall",
                    email: "jhall1@an.edu"
                },
                {
                    name: "Paula Vaughan-Burroughs",
                    email: "pvaughanburroughs@an.edu"
                },
                {
                    name: "Karen Williams",
                    email: "kwilliams3@an.edu"
                },
                {
                    name: "Dree Lynn",
                    email: "dlynn@an.edu"
                },
                {
                    name: "Linda Ebel",
                    email: "lebel@an.edu"
                },
                {
                    name: "Racquel Rogers",
                    email: "rrogers@an.edu"
                },
                {
                    name: "Timothy Lawson",
                    email: "timlawson@an.edu"
                },
                {
                    name: "Charles Markum",
                    email: "cmarkum@an.edu"
                },
                {
                    name: "Abigail Whelan",
                    email: "awhelan@an.edu"
                },
                {
                    name: "Grechen Murdaugh",
                    email: "gmurdaugh@an.edu"
                },
                {
                    name: "Terry Bartley",
                    email: "tbartley@an.edu"
                },
                {
                    name: "Stephen Hammonds",
                    email: "shammonds@an.edu"
                }
            ],
            onlinePrograms: [
                "MBA",
                "MSC",
                "MSIT",
                "BBACC",
                "BBAMG",
                "CSYB",
                "NETB",
                "MHSM18",
                "RN-BSN",
                "BAA",
                "MGT",
                "NET-A",
                "HIM17",
                "PTA-2018",
                // "ACC",
                // "BAM",
                "PBY-ECG17",
                "AMAA-C",
                "PLG",
                "PLGC",
                "AMAA",
                "MAA",
                "MCD17",
                "PTH-2018",
                "AMAC",
                "undecided",
                "MHSM"
            ],
            groundPrograms: []
        },
        {
            name: "ANU - Nashville, TN",
            code: "NVL",
            advisors: [
                {
                    name: "Unassigned Advisor",
                    email: "admissionsklassapp@an.edu"
                },
                {
                    name: "Chelsea Varney",
                    email: "cvarney@an.edu"
                },
                {
                    name: "Cody Chaney",
                    email: "cchaney@an.edu"
                },
                {
                    name: "Melanie Rutledge",
                    email: "mrutledge@an.edu"
                },
                {
                    name: "Anne Snyder",
                    email: "ansnyder@an.edu"
                },
                {
                    name: "Tonya Overbey",
                    email: "toverbey@an.edu"
                },
                {
                    name: "Katie McIlvain",
                    email: "kwilson@an.edu"
                },
                {
                    name: "Mary Oxenrider",
                    email: "moxenrider@an.edu"
                },
                {
                    name: "Jessica Williams",
                    email: "jawilliams@an.edu"
                },
                {
                    name: "Becki Sessions",
                    email: "bsessions@an.edu"
                },
                {
                    name: "Sheritha Smith",
                    email: "shersmith@an.edu"
                },
                {
                    name: "Trent Ramey",
                    email: "tramey@an.edu"
                },
                {
                    name: "Chelsea Dorris",
                    email: "cdorris@an.edu"
                },
                {
                    name: "Hilary Carman",
                    email: "hcarman@an.edu"
                },
                {
                    name: "Yvette Arceneaux",
                    email: "yarceneaux@an.edu"
                },
                {
                    name: "Elizabeth Rinehart",
                    email: "erinehart@an.edu"
                },
                {
                    name: "Hannah Jackson",
                    email: "hjackson@an.edu"
                },
                {
                    name: "Kary Alicea",
                    email: "kalicea@an.edu"
                },
                {
                    name: "Jenny Sayer",
                    email: "jsayer@an.edu"
                },
                {
                    name: "LynnSha Howard",
                    email: "lhoward@an.edu"
                },
                {
                    name: "Josh Tucker",
                    email: "jtucker1@an.edu"
                },
                {
                    name: "Megan Warren",
                    email: "mwarren@an.edu"
                },
                {
                    name: "Jennifer Hall",
                    email: "jhall1@an.edu"
                },
                {
                    name: "Paula Vaughan-Burroughs",
                    email: "pvaughanburroughs@an.edu"
                },
                {
                    name: "Karen Williams",
                    email: "kwilliams3@an.edu"
                },
                {
                    name: "Dree Lynn",
                    email: "dlynn@an.edu"
                },
                {
                    name: "Linda Ebel",
                    email: "lebel@an.edu"
                },
                {
                    name: "Racquel Rogers",
                    email: "rrogers@an.edu"
                },
                {
                    name: "Timothy Lawson",
                    email: "timlawson@an.edu"
                },
                {
                    name: "Charles Markum",
                    email: "cmarkum@an.edu"
                }
            ],
            onlinePrograms: [],
            groundPrograms: []
        },
        {
            name: "ANU - Bartlett, TN",
            code: "BRT",
            advisors: [
                {
                    name: "Unassigned Advisor",
                    email: "admissionsklassapp@an.edu"
                },
                {
                    name: "Chelsea Varney",
                    email: "cvarney@an.edu"
                },
                {
                    name: "Cody Chaney",
                    email: "cchaney@an.edu"
                },
                {
                    name: "Melanie Rutledge",
                    email: "mrutledge@an.edu"
                },
                {
                    name: "Anne Snyder",
                    email: "ansnyder@an.edu"
                },
                {
                    name: "Tonya Overbey",
                    email: "toverbey@an.edu"
                },
                {
                    name: "Katie McIlvain",
                    email: "kwilson@an.edu"
                },
                {
                    name: "Mary Oxenrider",
                    email: "moxenrider@an.edu"
                },
                {
                    name: "Jessica Williams",
                    email: "jawilliams@an.edu"
                },
                {
                    name: "Raya Banks",
                    email: "rdbanks@an.edu"
                },
                {
                    name: "Kiara Taylor ",
                    email: "kmtaylor@an.edu"
                },
                {
                    name: "Shaqueria Criner",
                    email: "scriner@an.edu"
                },
                {
                    name: "Trent Ramey",
                    email: "tramey@an.edu"
                },
                {
                    name: "Chelsea Dorris",
                    email: "cdorris@an.edu"
                },
                {
                    name: "Hilary Carman",
                    email: "hcarman@an.edu"
                },
                {
                    name: "Yvette Arceneaux",
                    email: "yarceneaux@an.edu"
                },
                {
                    name: "Elizabeth Rinehart",
                    email: "erinehart@an.edu"
                },
                {
                    name: "Hannah Jackson",
                    email: "hjackson@an.edu"
                },
                {
                    name: "Kary Alicea",
                    email: "kalicea@an.edu"
                },
                {
                    name: "Jenny Sayer",
                    email: "jsayer@an.edu"
                },
                {
                    name: "LynnSha Howard",
                    email: "lhoward@an.edu"
                },
                {
                    name: "Josh Tucker",
                    email: "jtucker1@an.edu"
                },
                {
                    name: "Megan Warren",
                    email: "mwarren@an.edu"
                },
                {
                    name: "Jennifer Hall",
                    email: "jhall1@an.edu"
                },
                {
                    name: "Paula Vaughan-Burroughs",
                    email: "pvaughanburroughs@an.edu"
                },
                {
                    name: "Karen Williams",
                    email: "kwilliams3@an.edu"
                },
                {
                    name: "Dree Lynn",
                    email: "dlynn@an.edu"
                },
                {
                    name: "Linda Ebel",
                    email: "lebel@an.edu"
                },
                {
                    name: "Racquel Rogers",
                    email: "rrogers@an.edu"
                },
                {
                    name: "Timothy Lawson",
                    email: "timlawson@an.edu"
                },
                {
                    name: "Charles Markum",
                    email: "cmarkum@an.edu"
                }
            ],
            onlinePrograms: [],
            groundPrograms: []
        },
        // ------ Campuses & Advisors Only for CDL program -------
        {
            name: "ANU - Distance Education Division ",
            code: "???",
            advisors: [
                {
                    name: "Unassigned Advisor",
                    email: "admissionsklassapp@an.edu"
                },
                {
                    name: "Chelsea Varney",
                    email: "cvarney@an.edu"
                },
                {
                    name: "Cody Chaney",
                    email: "cchaney@an.edu"
                },
                {
                    name: "Melanie Rutledge",
                    email: "mrutledge@an.edu"
                },
                {
                    name: "Adrian McQuarter",
                    email: "amcquarter@an.edu"
                },
                {
                    name: "Chequita Parker",
                    email: "cparker1@an.edu"
                },
                {
                    name: "Renee Miller",
                    email: "rmiller@an.edu"
                },
                {
                    name: "Nolan Sanders",
                    email: "nsanders@an.edu"
                },
                {
                    name: "Michael Sutton",
                    email: "msutton@an.edu"
                },
                {
                    name: "Chelsea Dorris",
                    email: "cdorris@an.edu"
                },
                {
                    name: "Hilary Carman",
                    email: "hcarman@an.edu"
                },
                {
                    name: "Yvette Arceneaux",
                    email: "yarceneaux@an.edu"
                },
                {
                    name: "Elizabeth Rinehart",
                    email: "erinehart@an.edu"
                },
                {
                    name: "Hannah Jackson",
                    email: "hjackson@an.edu"
                },
                {
                    name: "Kary Alicea",
                    email: "kalicea@an.edu"
                },
                {
                    name: "Jenny Sayer",
                    email: "jsayer@an.edu"
                },
                {
                    name: "LynnSha Howard",
                    email: "lhoward@an.edu"
                },
                {
                    name: "Josh Tucker",
                    email: "jtucker1@an.edu"
                },
                {
                    name: "Megan Warren",
                    email: "mwarren@an.edu"
                },
                {
                    name: "Jennifer Hall",
                    email: "jhall1@an.edu"
                },
                {
                    name: "Paula Vaughan-Burroughs",
                    email: "pvaughanburroughs@an.edu"
                },
                {
                    name: "Karen Williams",
                    email: "kwilliams3@an.edu"
                },
                {
                    name: "Dree Lynn",
                    email: "dlynn@an.edu"
                },
                {
                    name: "Linda Ebel",
                    email: "lebel@an.edu"
                },
                {
                    name: "Racquel Rogers",
                    email: "rrogers@an.edu"
                },
                {
                    name: "Timothy Lawson",
                    email: "timlawson@an.edu"
                },
                {
                    name: "Charles Markum",
                    email: "cmarkum@an.edu"
                },
                {
                    name: "Abigail Whelan",
                    email: "awhelan@an.edu"
                },
                {
                    name: "Grechen Murdaugh",
                    email: "gmurdaugh@an.edu"
                },
                {
                    name: "Terry Bartley",
                    email: "tbartley@an.edu"
                },
                {
                    name: "Stephen Hammonds",
                    email: "shammonds@an.edu"
                }
            ],
            onlinePrograms: [],
            groundPrograms: []
        },
        {
            name: "ANUKY – Louisville (E-Learning) ",
            code: "LOU",
            advisors: [
                {
                    name: "Unassigned Advisor",
                    email: "admissionsklassapp@an.edu"
                },
                {
                    name: "Joshua Raines",
                    email: "jraines@an.edu"
                },
                {
                    name: "Michael Sutton",
                    email: "msutton@an.edu"
                },
                {
                    name: "Cheria Goodson",
                    email: "cgoodson@an.edu"
                }
            ],
            onlinePrograms: [],
            groundPrograms: ["CDL"]
        }
    ],
    programs: [
        {
            code: "undecided",
            name: "Undecided"
        },
        // Certificate Program
        {
            code: "AMAC",
            name: "Administrative Medical Assisting - Certification",
            value: "Admin Medical Assisting (Certificate)"
        },
        {
            code: "AMAA-C",
            name: "Medical Assisting [Certificate Program]"
        },
        {
            code: "PLGC",
            name: "Paralegal Certificate Program"
        },
        // Diploma Program
        // {
        //     code: "ACC",
        //     name: "Accounting (Diploma Program)"
        // },
        {
            code: "MCD17",
            name: "Medical Billing and Coding"
        },
        {
            code: "PTH-2018",
            name: "Pharmacy Technician"
        },
        {
            code: "PBY-ECG17",
            name: "Phlebotomy and ECG Technician"
        },
        // {
        //     code: "BAM",
        //     name: "Business Administration (Diploma Program)"
        // },
        // Associate of Science
        {
            code: "AMAA",
            name: "Administrative Medical Assisting (Associates)"
        },
        {
            code: "BAA",
            name: "Business Administration - Accounting (Associate Program)"
        },
        {
            code: "MGT",
            name: "Business Administration - Management (Associate Program)"
        },
        {
            code: "HIM17",
            name: "Health Information Management"
        },
        {
            code: "MAA",
            name: "Medical Assisting Associate"
        },
        {
            code: "NET-A",
            name: "Network Support Associate Program"
        },
        {
            code: "PLG",
            name: "Paralegal"
        },
        {
            code: "PTA-2018",
            name: "Pharmacy Technician (Associate Program)"
        },
        {
            code: "MHSM",
            name: "Medical and Health Services Management"
        },
        // Bachelor
        {
            code: "CSYB",
            name: "Cybersecurity (Bachelor Program)"
        },
        {
            code: "MHSM18",
            name: "Medical and Health Services Management (Bachelor)"
        },
        {
            code: "NETB",
            name: "Network Administration (Bachelor Program)"
        },
        {
            code: "RN-BSN",
            name: "RN-BSN (RN to BSN Program)"
        },
        {
            code: "BBAMG",
            name: "Business Administration - Management (Bachelor Program)"
        },
        {
            code: "BBACC",
            name: "Business Administration - Accounting (Bachelor Program)"
        },
        {
            code: "BSLS",
            name: "Bachelor of Science in Legal Studies (Bachelor)"
        },
        // Master
        {
            code: "MBA",
            name: "Master of Business Administration"
        },
        {
            code: "MSC",
            name: "Master of Science in Cybersecurity"
        },
        {
            code: "MSIT",
            name: "Master of Information Technology"
        },
        {
            code: "MSSD",
            name: "Master of Science in Software Development"
        },
        {
            code: "MSCSE",
            name: "Master of Science in Computer Science and Engineering"
        },
        // Not especified
        {
            code: "ADN",
            name: "Nursing"
        },
        {
            code: "CDL",
            name: "CDL"
        }
    ],
    states: [
        {name: "Alabama", abbreviation: "AL"},
        {name: "Alaska", abbreviation: "AK"},
        {name: "Arizona", abbreviation: "AZ"},
        {name: "Arkansas", abbreviation: "AR"},
        {name: "California", abbreviation: "CA"},
        {name: "Colorado", abbreviation: "CO"},
        {name: "Connecticut", abbreviation: "CT"},
        {name: "Delaware", abbreviation: "DE"},
        {name: "District of Columbia", abbreviation: "DC"},
        {name: "Florida", abbreviation: "FL"},
        {name: "Georgia", abbreviation: "GA"},
        {name: "Hawaii", abbreviation: "HI"},
        {name: "Idaho", abbreviation: "ID"},
        {name: "Illinois", abbreviation: "IL"},
        {name: "Indiana", abbreviation: "IN"},
        {name: "Iowa", abbreviation: "IA"},
        {name: "Kansas", abbreviation: "KS"},
        {name: "Kentucky", abbreviation: "KY"},
        {name: "Lousiana", abbreviation: "LA"},
        {name: "Maine", abbreviation: "ME"},
        {name: "Maryland", abbreviation: "MD"},
        {name: "Massachusetts", abbreviation: "MA"},
        {name: "Michigan", abbreviation: "MI"},
        {name: "Minnesota", abbreviation: "MN"},
        {name: "Mississippi", abbreviation: "MS"},
        {name: "Missouri", abbreviation: "MO"},
        {name: "Montana", abbreviation: "MT"},
        {name: "Nebraska", abbreviation: "NE"},
        {name: "Nevada", abbreviation: "NV"},
        {name: "New Hampshire", abbreviation: "NH"},
        {name: "New Jersey", abbreviation: "NJ"},
        {name: "New Mexico", abbreviation: "NM"},
        {name: "New York", abbreviation: "NY"},
        {name: "North Carolina", abbreviation: "NC"},
        {name: "North Dakota", abbreviation: "ND"},
        {name: "Ohio", abbreviation: "OH"},
        {name: "Oklahoma", abbreviation: "OK"},
        {name: "Oregon", abbreviation: "OR"},
        {name: "Pennsylvania", abbreviation: "PA"},
        {name: "Rhode Island", abbreviation: "RI"},
        {name: "South Carolina", abbreviation: "SC"},
        {name: "South Dakota", abbreviation: "SD"},
        {name: "Tennessee", abbreviation: "TN"},
        {name: "Texas", abbreviation: "TX"},
        {name: "Utah", abbreviation: "UT"},
        {name: "Vermont", abbreviation: "VT"},
        {name: "Virginia", abbreviation: "VA"},
        {name: "Washington", abbreviation: "WA"},
        {name: "West Virginia", abbreviation: "WV"},
        {name: "Wisconsin", abbreviation: "WI"},
        {name: "Wyoming", abbreviation: "WY"}
    ],
    tabs: [
        {
            step: "1",
            title: "Application"
        },
        {
            step: "2",
            title: "Education Disclosure"
        },
        {
            step: "3",
            title: "Code of Computing"
        },
        {
            step: "4",
            title: "Felony Acknowledgement Form"
        },
        {
            step: "5",
            title: "Identity Authentication Form"
        },
        {
            step: "6",
            title: "Online Education Disclosure"
        },
        {
            step: "7",
            title: "CPA Eligibility Form"
        },
        {
            step: "8",
            title: "Technical Standards Form"
        },
        {
            step: "9",
            title: "HIM Technical Standards"
        },
        {
            step: "10",
            title: "Transferability of Credits Form"
        },
        {
            step: "11",
            title: "Disclosure of Info Mississippi"
        },
        {
            step: "12",
            title: "Finish & Submit"
        }
    ]
}
