import React, {Component} from "react"
import styles from "./SubmissionForm.module.css"
import classNames from "classnames"
import {JsonDocument, Model} from "Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {findErrors} from "lib/functions/findErrors"
import {DataStore} from "data/DataStore"

interface Props {
    model: Model
}

const rules = {}

@observer
export class SubmissionForm extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.json_data = props.model.document.json_data

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        console.log("Validate EmploymentDetails")
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private get dataStore(): DataStore {
        return this.props.model.dataStore
    }

    private onChange(id: string, value) {
        this.json_data[id] = value

        this.forceUpdate()
    }

    public render() {
        const p = {model: this.props.model, onChange: this.onChange, errors: this.errors}

        let message = "Please click the submit button below to complete your submission"
        if (p.model.is_form_submitted) {
            message = "Congratulations on taking the next step in advancing your career!"
        }

        if (!this.props.model.canSubmit()) {
            message = "Please fill all forms before submit"
        }

        return (
            <div className={styles.root}>
                <h2 className={styles.h2}>{""}</h2>
                <h3 className={classNames(styles.h3, styles.hidden)}>Submit Form</h3>
                <div style={{marginTop: "60px"}}>{message}</div>
            </div>
        )
    }
}
