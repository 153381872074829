import React, {Component} from "react"
import styles from "./AdditionalInformation.module.css"
import {Label} from "components/inputs/Label"
import {Input} from "components/inputs/input"
import {RadioButton} from "components/inputs/RadioButton"
import {LabelRadio} from "components/inputs/LabelRadio"
import {JsonDocument, Model} from "Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {findErrors} from "lib/functions/findErrors"
import {DataStore} from "data/DataStore"
import {Select} from "components/inputs/Select"
import {REFILL_FORMS_IDS, refreshSignaturesOrInitials} from "lib/functions/helper"

interface Props {
    model: Model
}

const mandatory = {
    presence: {allowEmpty: false}
}
const rules = {
    country_of_citizenship: mandatory,
    marital_status: mandatory,
    gender: mandatory,
    is_veteran: mandatory,
    military_benefits: mandatory,
    funding_through_agency: mandatory,
    ethnicity_describe: mandatory,
    ethnicity: mandatory
}

@observer
export class AdditionalInformation extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.json_data = props.model.document.json_data

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        console.log("Validate AdditionalInformation")
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private get dataStore(): DataStore {
        return this.props.model.dataStore
    }

    private onChange(id: string, value) {
        this.json_data[id] = value
        this.removeIdsFromDocument(this.json_data)
        this.forceUpdate()
        this.props.model.dispatcher.dispatch()
    }

    private removeIdsFromDocument(json_data) {
        refreshSignaturesOrInitials(
            json_data,
            [...REFILL_FORMS_IDS.application.forms, ...REFILL_FORMS_IDS.withNoOnchange.forms],
            [...REFILL_FORMS_IDS.application.ids, ...REFILL_FORMS_IDS.withNoOnchange.ids]
        )
    }

    public render() {
        const p = {json_data: this.json_data, onChange: this.onChange, errors: this.errors}
        const r = {onChange: this.onChange, json_data: this.json_data}

        return (
            <div className={styles.root}>
                <h2 className={styles.h2}>GENERAL INFORMATION</h2>
                <h2 className={styles.h3}>ADDITIONAL INFORMATION</h2>

                <div className={styles.form}>
                    <Label text="Country of Citizenship" required className={styles.labelM0}>
                        <Input id="country_of_citizenship" {...p} />
                    </Label>

                    <Label text="Marital Status" required className={styles.label}>
                        <Select
                            id="marital_status"
                            options={this.dataStore.marital_status}
                            onChange={this.onChange}
                            model={this.props.model}
                            errors={this.errors}
                        />
                    </Label>
                </div>
                <div className={styles.form2}>
                    <Label text="Sex" required className={styles.label2}>
                        <Select
                            id="gender"
                            options={this.dataStore.gender}
                            onChange={this.onChange}
                            model={this.props.model}
                            errors={this.errors}
                        />
                    </Label>
                    <p className={styles.paragraph}>
                        <strong>ANU </strong>
                        is a recipient of federal dollars and is required by the federal government
                        to solicit certain demographic information to meet federal reporting
                        requirements. Applicants are requested to provide the information
                        voluntarily. This information will not be utilized in a discriminatory
                        manner.
                    </p>
                </div>
                <div className={styles.form}>
                    <LabelRadio
                        text="Ethnicity: Are you Hispanic?"
                        required
                        error={this.errors["ethnicity"]}
                        className={styles.labelRadio}>
                        <>
                            <RadioButton label="Yes" value="yes" group_name="ethnicity" {...r} />
                            <RadioButton label="No" value="no" group_name="ethnicity" {...r} />
                        </>
                    </LabelRadio>
                    <Label
                        text="How would you describe yourself?"
                        className={styles.label}
                        required>
                        <Select
                            id="ethnicity_describe"
                            options={this.dataStore.getEthnicity}
                            onChange={this.onChange}
                            model={this.props.model}
                            errors={this.errors}
                        />
                    </Label>
                    <LabelRadio
                        text="Are you a Veteran / Active /
                        Reservist / Dependent?"
                        required
                        error={this.errors["is_veteran"]}
                        className={styles.labelRadio}>
                        <>
                            <RadioButton label="Yes" value="yes" group_name="is_veteran" {...r} />
                            <RadioButton label="No" value="no" group_name="is_veteran" {...r} />
                        </>
                    </LabelRadio>

                    <LabelRadio
                        text="Will you receive military/veterans educational benefits?"
                        required
                        className={styles.labelRadio}
                        error={this.errors["military_benefits"]}>
                        <>
                            <RadioButton
                                label="Yes"
                                value="yes"
                                group_name="military_benefits"
                                {...r}
                            />
                            <RadioButton
                                label="No"
                                value="no"
                                group_name="military_benefits"
                                {...r}
                            />
                        </>
                    </LabelRadio>

                    <LabelRadio
                        text="Might you qualify for tuition
                        funding through an agency?"
                        required
                        error={this.errors["funding_through_agency"]}
                        className={styles.labelRadio}>
                        <>
                            <RadioButton
                                label="Yes"
                                value="yes"
                                group_name="funding_through_agency"
                                {...r}
                            />
                            <RadioButton
                                label="No"
                                value="no"
                                group_name="funding_through_agency"
                                {...r}
                            />
                        </>
                    </LabelRadio>

                    <Label text="Name of Agency" className={styles.labelAgency}>
                        <Input id="name_of_agency" {...p} />
                    </Label>
                </div>
                <p className={styles.text}>
                    <span>*</span> Indicates that a response is required
                </p>
            </div>
        )
    }
}
