import React, {Component} from "react"
import classNames from "classnames"
import styles from "./PersonalInformation.module.css"
import {Label} from "components/inputs/Label"
import {Input} from "components/inputs/input"
import {JsonDocument, Model} from "../../Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {findErrors} from "lib/functions/findErrors"
import {DataStore} from "data/DataStore"
import {getParameterByName} from "lib/functions/getParameterByName"
import {REFILL_FORMS_IDS, refreshSignaturesOrInitials} from "lib/functions/helper"

interface Props {
    model: Model
}

const mandatory = {
    presence: {allowEmpty: false}
}
const rules = {
    ssn: mandatory,
    date_of_birth: mandatory,
    first_name: {
        ...mandatory,
        format: {
            pattern: "[a-zA-Z-.' ]+",
            flags: "i",
            message: "can only contain characters, spaces, up quotes and dots",
            length: {
                minimum: 1,
                maximum: 20
            }
        }
    },
    last_name: {
        ...mandatory,
        format: {
            pattern: "[a-zA-Z-.' ]+",
            flags: "i",
            message: "can only contain characters, spaces, up quotes and dots",
            length: {
                minimum: 1,
                maximum: 30
            }
        }
    }
}

@observer
export class PersonalInformation extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.json_data = props.model.document.json_data

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        console.log("Validate WEB PersonalInformation")
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private get dataStore(): DataStore {
        return this.props.model.dataStore
    }

    private onChange(id: string, value) {
        this.json_data[id] = value
        this.removeIdsFromDocument(this.json_data)
        this.forceUpdate()
        this.props.model.dispatcher.dispatch()
    }

    private removeIdsFromDocument(json_data) {
        refreshSignaturesOrInitials(
            json_data,
            [...REFILL_FORMS_IDS.application.forms, ...REFILL_FORMS_IDS.withNoOnchange.forms],
            [...REFILL_FORMS_IDS.application.ids, ...REFILL_FORMS_IDS.withNoOnchange.ids]
        )
    }

    public render() {
        const p = {json_data: this.json_data, onChange: this.onChange, errors: this.errors}
        return (
            <div className={styles.root}>
                <h2 className={styles.h2}>GENERAL INFORMATION</h2>
                <h3 className={classNames(styles.h3, styles.hidden)}>PERSONAL INFORMATION</h3>
                <div className={styles.form}>
                    <Label text="First Name" required className={styles.label}>
                        <Input id="first_name" {...p} />
                    </Label>

                    <Label text="Last Name" required className={styles.label}>
                        <Input id="last_name" {...p} />
                    </Label>

                    <Label text="Former Last Name" className={styles.label}>
                        <Input id="former_last_name" {...p} />
                    </Label>

                    <Label text="D.O.B" required className={styles.label}>
                        <Input id="date_of_birth" type="date" {...p} />
                    </Label>

                    <Label text="SSN" required className={styles.label}>
                        <Input id="ssn" {...p} />
                    </Label>
                </div>
            </div>
        )
    }
}
