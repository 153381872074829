import React, {Component} from "react"
import styles from "./ContactDetails.module.css"
import {Label} from "components/inputs/Label"
import {Input} from "components/inputs/input"
import {JsonDocument, Model} from "Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {findErrors} from "lib/functions/findErrors"
import {DataStore} from "data/DataStore"
import {Select} from "components/inputs/Select"
import {REFILL_FORMS_IDS, refreshSignaturesOrInitials} from "lib/functions/helper"

interface Props {
    model: Model
}

const mandatory = {
    presence: {allowEmpty: false}
}
const rules = {
    street_address: mandatory,
    city: mandatory,
    state: mandatory,
    zip_code: {
        ...mandatory,
        format: {
            pattern: /^\d{5}(?:[-\s]\d{4})?$/,
            flags: "gm",
            message: "^Invalid zip code format [XXXXX or XXXXX-XXXX]"
        }
    },
    home_phone: mandatory,
    email: {
        ...mandatory,
        format: {
            pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            flags: "i",
            message: "^Is not a valid email"
        }
    },
    mobile_phone: mandatory
}

@observer
export class ContactDetails extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.json_data = props.model.document.json_data

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        console.log("Validate WEB ContactDetails")
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private get dataStore(): DataStore {
        return this.props.model.dataStore
    }

    private onChange(id: string, value) {
        this.json_data[id] = value
        this.removeIdsFromDocument(this.json_data)
        this.props.model.dispatcher2.dispatch()
        this.forceUpdate()
    }

    private removeIdsFromDocument(json_data) {
        refreshSignaturesOrInitials(
            json_data,
            [...REFILL_FORMS_IDS.application.forms, ...REFILL_FORMS_IDS.withNoOnchange.forms],
            [...REFILL_FORMS_IDS.application.ids, ...REFILL_FORMS_IDS.withNoOnchange.ids]
        )
    }

    public render() {
        const p = {json_data: this.json_data, onChange: this.onChange, errors: this.errors}
        return (
            <div className={styles.root}>
                <h2 className={styles.h2}>GENERAL INFORMATION</h2>

                <h2 className={styles.h3}>CONTACT INFORMATION</h2>

                <div className={styles.form}>
                    <Label text="Street Address" required className={styles.label}>
                        <Input id="street_address" {...p} />
                    </Label>

                    <Label text="City" required className={styles.label}>
                        <Input id="city" {...p} />
                    </Label>

                    <Label text="State" required className={styles.label}>
                        <Select
                            id="state"
                            options={this.dataStore.getAllStates()}
                            onChange={this.onChange}
                            model={this.props.model}
                            errors={this.errors}
                        />
                    </Label>

                    <Label text="ZIP Code" required className={styles.label}>
                        <Input id="zip_code" {...p} />
                    </Label>

                    <Label text="Home Phone" required className={styles.label}>
                        <Input id="home_phone" {...p} />
                    </Label>

                    <Label text="County" className={styles.label}>
                        <Input id="county" {...p} />
                    </Label>

                    <Label text="Work Phone" className={styles.label}>
                        <Input id="work_phone" {...p} />
                    </Label>

                    <Label text="Mobile Phone" required className={styles.label}>
                        <Input id="mobile_phone" {...p} />
                    </Label>

                    <Label text="Email" required className={styles.label}>
                        <Input id="email" {...p} />
                    </Label>
                </div>
            </div>
        )
    }
}
