import React, {Component} from "react"
import {Model} from "../../Model"
import {CareerInformation} from "../../pages/career-information"
import {Main} from "../../pages/main"
import {observer} from "mobx-react"
import {EmploymentDetails} from "pages/employment-details"
import {HighSchoolDetails} from "pages/high-school-details/HighSchoolDetails"
import {PreviousCollege} from "pages/previous-college"
import {AdditionalInformation} from "../../pages/application-additional-information"
import {PersonalInformation} from "../../pages/application-personal-information"
import {EmergencyContact} from "../../pages/application-emergency-contact"
import {ContactDetails} from "../../pages/application-contact-details"
import {AppointmentDetails} from "../../pages/appointment-details"
import {SubmissionForm} from "../../pages/submission-form"

interface Props {
    model: Model
}

@observer
export class Form extends Component<Props, {}> {
    public render() {
        const model = this.props.model

        let html = <Main model={model} />

        const form_id = model.form.form_id

        if (form_id === "application_personal_information") {
            html = <PersonalInformation model={model} />
        }

        if (form_id === "application_emergency_contact") {
            html = <EmergencyContact model={model} />
        }

        if (form_id === "application_contact_details") {
            html = <ContactDetails model={model} />
        }

        if (form_id === "application_career_information") {
            html = <CareerInformation model={model} />
        }

        if (form_id === "application_additional_information") {
            html = <AdditionalInformation model={model} />
        }

        if (form_id === "application_employment_details") {
            html = <EmploymentDetails model={model} />
        }

        if (form_id === "application_high_school_details") {
            html = <HighSchoolDetails model={model} />
        }

        if (form_id === "application_previous_college") {
            html = <PreviousCollege model={model} />
        }

        if (form_id === "appointment_details") {
            html = <AppointmentDetails model={model} />
        }

        if (form_id === "submission") {
            html = <SubmissionForm model={model} />
        }

        return html
    }
}
