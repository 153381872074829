import React, {Component} from "react"
import styles from "./AppointmentDetails.module.css"
import {Label} from "components/inputs/Label"
import classNames from "classnames"
import {JsonDocument, Model} from "Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {findErrors} from "lib/functions/findErrors"
import {DataStore} from "data/DataStore"
import {Select} from "../../components/inputs/Select"
import {Input} from "../../components/inputs/input"

interface Props {
    model: Model
}

// const mandatory = {
//     presence: {allowEmpty: false}
// }
const rules = {
    // appointment_date: mandatory,
    // appointment_time: mandatory
}

@observer
export class AppointmentDetails extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.json_data = props.model.document.json_data

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        console.log("Validate EmploymentDetails")
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private get dataStore(): DataStore {
        return this.props.model.dataStore
    }

    private onChange(id: string, value) {
        this.json_data[id] = value

        this.forceUpdate()
    }

    private getTomorrowDate() {
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        let d = new Date(tomorrow),
            month = "" + (d.getMonth() + 1),
            day = "" + d.getDate(),
            year = d.getFullYear()

        if (month.length < 2) month = "0" + month
        if (day.length < 2) day = "0" + day

        return [year, month, day].join("-")
    }

    public render() {
        const p = {model: this.props.model, onChange: this.onChange, errors: this.errors}

        return (
            <div className={styles.root}>
                <h2 className={styles.h2}>REQUEST (OPTIONAL)</h2>
                <h3 className={classNames(styles.h3, styles.hidden)}>REQUEST DETAILS</h3>
                <p className={styles.note}>
                    If you need assistance with FAFSA or would like to speak with Financial Aid
                    Advisor, please select your preferred date and time for an appointment with the
                    Financial Aid Advisor. Time is in the US Eastern Timezone. A Financial Aid
                    Advisor will contact you to confirm your appointment request.
                </p>
                <p className={styles.note}>
                    FAFSA School Codes for American National University
                    <br />
                    Kentucky: 010489 and 003726 <br />
                    All Other States: 003726 <br />
                </p>
                <div style={{marginTop: "60px"}}></div>
                <div className={styles.form}>
                    <Label text="Date">
                        <Input
                            id="appointment_date"
                            type="date"
                            min={this.getTomorrowDate()}
                            // max="2019-06-08"
                            {...{
                                json_data: this.json_data,
                                onChange: this.onChange,
                                errors: this.errors
                            }}
                        />
                    </Label>

                    <Label text="Time">
                        <Select
                            id="appointment_time"
                            options={[
                                {label: "10:00 AM", value: "10:00 AM"},
                                {label: "10:30 AM", value: "10:30 AM"},
                                {label: "11:00 AM", value: "11:00 AM"},
                                {label: "11:30 AM", value: "11:30 AM"},
                                {label: "12:00 PM", value: "12:00 PM"},
                                {label: "12:30 PM", value: "12:30 PM"},
                                {label: "1:00 PM", value: "1:00 PM"},
                                {label: "1:30 PM", value: "1:30 PM"},
                                {label: "2:00 PM", value: "2:00 PM"},
                                {label: "2:30 PM", value: "2:30 PM"},
                                {label: "3:00 PM", value: "3:00 PM"},
                                {label: "3:30 PM", value: "3:30 PM"},
                                {label: "4:00 PM", value: "4:00 PM"},
                                {label: "4:30 PM", value: "4:30 PM"},
                                {label: "5:00 PM", value: "5:00 PM"}
                            ]}
                            {...p}
                        />
                    </Label>
                </div>

                <div className={styles.form}>
                    <Label text="Have you completed FAFSA?">
                        <Select
                            id="have_you_completed_fafsa"
                            options={[
                                {label: "Yes", value: "Yes"},
                                {label: "No", value: "No"}
                            ]}
                            {...p}
                        />
                    </Label>

                    <Label text="Did you include ANU School Code in your FAFSA Application?">
                        <Select
                            id="did_you_include_anu_code_in_fafsa"
                            options={[
                                {label: "Yes", value: "Yes"},
                                {label: "No", value: "No"}
                            ]}
                            {...p}
                        />
                    </Label>
                </div>
                <div className={styles.form}>
                    <Label text="Do you need assistance completing FAFSA?">
                        <Select
                            id="need_assistance_completing_fafsa"
                            options={[
                                {label: "Yes", value: "Yes"},
                                {label: "No", value: "No"}
                            ]}
                            {...p}
                        />
                    </Label>
                </div>
            </div>
        )
    }
}
