import React, {Component} from "react"
import styles from "./PreviousCollege.module.css"
import {Label} from "components/inputs/Label"
import {Input} from "components/inputs/input"
import classNames from "classnames"
import {JsonDocument, Model} from "Model"
import {observer} from "mobx-react"
import {observable} from "mobx"
import {findErrors} from "lib/functions/findErrors"
import {DataStore} from "data/DataStore"
import {REFILL_FORMS_IDS, refreshSignaturesOrInitials} from "lib/functions/helper"

interface Props {
    model: Model
}

// const mandatory = {
//     presence: {allowEmpty: false}
// }
const rules = {}

@observer
export class PreviousCollege extends Component<Props, {}> {
    @observable
    private errors: {[key: string]: string} = {}

    private json_data: JsonDocument = {}

    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.json_data = props.model.document.json_data

        this.onModel = this.onModel.bind(this)
        props.model.dispatcher.addListener(this.onModel)
    }

    private onModel() {
        this.json_data = this.props.model.document.json_data
        this.forceUpdate()
    }

    public async componentDidMount() {
        this.props.model.dispatcher.dispatch()
        this.isValid = this.isValid.bind(this)
        // @ts-ignore
        this.isValid.functionId = this.constructor.name
        this.props.model.addValidateFunction(this.isValid)
    }

    public async componentWillUnmount() {
        this.props.model.removeValidateFunction(this.isValid)
        this.props.model.dispatcher.removeListener(this.onModel)
    }

    private isValid(): boolean {
        console.log("Validate PreviousCollege")
        const {isValid, errors} = findErrors(this.json_data, rules)
        this.errors = errors

        return isValid
    }

    private get dataStore(): DataStore {
        return this.props.model.dataStore
    }

    private onChange(id: string, value) {
        this.json_data[id] = value
        this.removeIdsFromDocument(this.json_data)
        this.forceUpdate()
        this.props.model.dispatcher.dispatch()
    }

    private removeIdsFromDocument(json_data) {
        refreshSignaturesOrInitials(
            json_data,
            [...REFILL_FORMS_IDS.application.forms, ...REFILL_FORMS_IDS.withNoOnchange.forms],
            [...REFILL_FORMS_IDS.application.ids, ...REFILL_FORMS_IDS.withNoOnchange.ids]
        )
    }

    public render() {
        const p = {json_data: this.json_data, onChange: this.onChange, errors: this.errors}
        return (
            <div className={styles.root}>
                <h2 className={styles.h2}>PREVIOUS COLLEGE</h2>
                <h3 className={classNames(styles.h3, styles.hidden)}>Experience</h3>
                <div className={styles.wrapper}>
                    <div className={styles.firstLine}>
                        <p className={styles.text}>College / University</p>
                        <p className={styles.text}>City / State</p>
                        <p className={styles.text}>Degree Earned</p>
                        <p className={styles.text}>Date Attended</p>
                    </div>
                    <div className={styles.form}>
                        <Label text="College  / University" className={styles.label}>
                            <Input id="previous_college_name_1" {...p} />
                        </Label>
                        <Label text="City / State" className={styles.label}>
                            <Input id="previous_college_city_1" {...p} />
                        </Label>

                        <Label text="Degrees Earned" className={styles.label}>
                            <Input id="previous_college_degree_1" {...p} />
                        </Label>

                        <Label text="Date Earned" className={styles.label}>
                            <Input id="previous_college_date_1" {...p} />
                        </Label>
                    </div>
                    <div className={styles.form}>
                        <Label text="College  / University" className={styles.label}>
                            <Input id="previous_college_name_2" {...p} />
                        </Label>

                        <Label text="City / State" className={styles.label}>
                            <Input id="previous_college_city_2" {...p} />
                        </Label>

                        <Label text="Degrees Earned" className={styles.label}>
                            <Input id="previous_college_degree_2" {...p} />
                        </Label>

                        <Label text="Date Earned" className={styles.label}>
                            <Input id="previous_college_date_2" {...p} />
                        </Label>
                    </div>
                    <div className={styles.form}>
                        <Label text="College  / University" className={styles.label}>
                            <Input id="previous_college_name_3" {...p} />
                        </Label>

                        <Label text="City / State" className={styles.label}>
                            <Input id="previous_college_city_3" {...p} />
                        </Label>

                        <Label text="Degrees Earned" className={styles.label}>
                            <Input id="previous_college_degree_3" {...p} />
                        </Label>

                        <Label text="Date Earned" className={styles.label}>
                            <Input id="previous_college_date_3" {...p} />
                        </Label>
                    </div>
                </div>
            </div>
        )
    }
}
