import {Model} from "./Model"

export class ModelListener {
    private cache = {}

    constructor(private model: Model) {
        this.onModel = this.onModel.bind(this)

        model.dispatcher2.addListener(this.onModel)
        this.onModel()
    }

    private removeFromJSONForms(json_data) {
        if (json_data.forms) {
            const filledForms = Object.keys(json_data.forms)
            if (
                filledForms.includes("technical_standards") &&
                !this.validateProgramTS(json_data.program_name)
            ) {
                delete json_data.forms.technical_standards
            }
        }
    }

    private onModel() {
        const json_data = this.model.document.json_data

        const enable = this.model.enableForm.bind(this.model)
        const disable = this.model.disableForm.bind(this.model)

        disable("identity_authentication")
        disable("online_education_disclosure")
        disable("cpa_eligibility")
        disable("technical_standards")
        disable("him_technical_standards")
        disable("transferability_of_credits")
        disable("him_enrollment_agreement")
        disable("recruitment")
        disable("covid_19")

        this.onCampusChange()
        this.showProgramForms()
        this.onStateChange()

        this.removeFromJSONForms(json_data)

        if (json_data.program_name === "CDL") {
            disable("application_previous_college")
        }

        let him17Campuses = [
            "ANU - Youngstown, OH",
            "ANU - Charlottesville, VA",
            "ANU - Danville, VA",
            "ANU - Harrisonburg, VA",
            "ANU - Lynchburg, VA",
            "ANU - Roanoke Valley, VA",
            "ANU - Dayton Area, OH",
            "ANU - Online Division"
        ]

        if (
            json_data.program_name === "Health Information Management" &&
            him17Campuses.indexOf(json_data.campus) !== -1
        ) {
            // enable("him_enrollment_agreement")
        } else {
            disable("him_enrollment_agreement")
        }

        // URGENT FIX
        // Please check relation with him17Campuses up
        if (json_data.program_name === "Health Information Management") {
            // enable("him_enrollment_agreement")
        }

        if (json_data.program_name === "CDL Training") {
            // enable(15)
            // enable(5)
            // enable(6)
            // disable(4)
            // disable(2)
            // disable(12)
        } else {
            enable("felony_acknowledgement")
            enable("education_disclosure")
            enable("transcript_request")
        }

        if (
            json_data.program_name === "Pharmacy Technician" ||
            json_data.program_name === "Pharmacy Technician (Associate Program)"
        ) {
            enable("covid_19")
        }
    }

    private onCampusChange() {
        const enable = this.model.enableForm.bind(this.model)
        const disable = this.model.disableForm.bind(this.model)

        disable("identity_authentication")
        disable("online_education_disclosure")
        // disable(15)
        disable("covid_19")

        if (this.model.document.json_data.campus === "ANU - Distance Education Division") {
            enable("identity_authentication")
            enable("online_education_disclosure")
        }
    }

    private onStateChange() {
        const enable = this.model.enableForm.bind(this.model)
        const disable = this.model.disableForm.bind(this.model)

        disable("transferability_of_credits")
        disable("immunization_verification")

        if (this.model.document.json_data.state === "Tennessee") {
            enable("transferability_of_credits")
            enable("immunization_verification")
        }
    }

    private showProgramForms() {
        const enable = this.model.enableForm.bind(this.model)
        const disable = this.model.disableForm.bind(this.model)

        disable("cpa_eligibility")
        disable("technical_standards")
        disable("him_technical_standards")

        let program = this.model.document.json_data.program_name

        if (program == null) {
            return
        }

        if (this.validateProgramTS(program)) {
            enable("technical_standards")
        }

        if (this.validateProgramHIM(program)) {
            enable("him_technical_standards")
        }

        if (this.validateProgramCPA(program)) {
            enable("cpa_eligibility")
        }
    }

    private validateProgramTS(program: string) {
        let programsArray = [
            "Surgical Technology & Central Sterile Processing",
            "Medical Assisting Associate",
            "Phlebotomy and ECG Technician",
            "Medical Assisting [Certificate Program]",
            "Nursing",
            "CDL",
            "Pharmacy Technician (Associate Program)",
            "Pharmacy Technician",
            "Medical Billing and Coding",
            "Administrative Medical Assisting (Associates)"
        ]

        if (programsArray.indexOf(program) !== -1) {
            return true
        }

        return false
    }

    private validateProgramHIM(program) {
        if (program === "Health Information Management") {
            return true
        }
        return false
    }

    private validateProgramCPA(program) {
        // None program for now
        let programsArray = []

        if (programsArray.indexOf(program) !== -1) {
            return true
        }

        return false
    }
}
